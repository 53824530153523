import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
  return (
    <div>
      <section className="home-light">
        <div className="row column large-8">
          <h2 className="section-title-light">Interactive Phishing Module</h2>
          <h2 className="section-subtitle-light">Test your knowledge!</h2>
        </div>
      </section>

      <section className="home-light">
        <CircularProgress color="secondary" size="1.5rem"/> 
      </section>
    </div>
  )
}

export default Loading;