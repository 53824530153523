import React, { Suspense, lazy } from "react"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"

import "./css/main.css"
import "./css/rowcolumn.css"

import Loading from "./components/Loading"

// IMPORTANT!
// This is called "code-splitting" and it improves page rendering as only the components
// (code) is rendered when needed. The <Loading/> component is shown as needed when rendering in process.
const Home = lazy(() => import("./Home"))
const Example_1 = lazy(() => import("./Example_1"))
const Example_2 = lazy(() => import("./Example_2"))
const Example_3 = lazy(() => import("./Example_3"))
const Example_4 = lazy(() => import("./Example_4"))
const Example_5 = lazy(() => import("./Example_5"))
const Example_6 = lazy(() => import("./Example_6"))
const Example_7 = lazy(() => import("./Example_7"))
const Example_8 = lazy(() => import("./Example_8"))
const Summary = lazy(() => import("./Summary"))
const Unknown = lazy(() => import("./Unknown"))

// Maintain browser history for app
const history = createBrowserHistory()

const App = () => {
  return (
    <div>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/ex1" component={Example_1} />
            <Route exact path="/ex2" component={Example_2} />
            <Route exact path="/ex3" component={Example_3} />
            <Route exact path="/ex4" component={Example_4} />
            <Route exact path="/ex5" component={Example_5} />
            <Route exact path="/ex6" component={Example_6} />
            <Route exact path="/ex7" component={Example_7} />
            <Route exact path="/ex8" component={Example_8} />
            <Route exact path="/summary" component={Summary} />
            <Route component={Unknown} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  )
}

export default App
